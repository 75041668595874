<template>
  <div class="app-container">
 
      <img src="@/assets/welcome.jpg" >
  </div>

</template>
<script>

// import { Message } from 'element-ui'
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
  padding: 0 0 0 0;
  background-color: white;
}
#app-contain{
  height:100%;
  width:100%;
}

</style>